import styled from 'styled-components';

import { darken } from 'polished';

import bgEmpada from '../../assets/empada.jpg';
import bgEmpada2 from '../../assets/empada2.jpg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;

  @media (orientation: portrait) and (max-width: 490px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    min-height: 100%;
  }

  @media (orientation: landscape) and (max-height: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    min-height: 100%;
  }
`;

export const MainLogo = styled.div`
  width: 50%;
  height: 100%;
  background: url(${bgEmpada}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  z-index: 20;
  border-right: 14px solid #f5886c;

  > img {
    width: 300px;
    margin-right: -157px;
    margin-bottom: 300px;
    z-index: 21;
  }

  @media (orientation: landscape) and (max-height: 480px) {
    height: 200px;
    width: 100%;
    max-width: 100%;
  }

  @media (orientation: portrait) and (max-width: 490px) {
    width: 100%;
    min-height: 280px;
    background: url(${bgEmpada2}) no-repeat center;
    background-size: cover;
    border-right: 0;
    border-bottom: 10px solid #f5886c;

    > img {
      align-self: flex-end;
      width: 200px;
      margin: 0 auto -100px;
      z-index: 21;
    }
  }

  @media (orientation: landscape) and (max-height: 480px) {
    width: 100%;
    height: 200px;
    min-height: 200px;
    background: url(${bgEmpada2}) no-repeat center;
    background-size: cover;
    border-right: 0;
    border-bottom: 10px solid #f5886c;
    align-items: center;

    > img {
      align-self: flex-end;
      width: 200px;
      margin: 0 auto -100px;
      z-index: 21;
    }
  }
`;

export const MainContent = styled.div`
  flex: 1;
  background: #deac79;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
  width: 100%;
  padding-bottom: 32px;

  h1 {
    text-align: center;
    padding: 8px 60px;
    font-family: 'Dancing Script', cursive;
    font-size: 4rem;
    color: #fff;
    width: 100%;
    background-color: #f5886c;
  }

  h2 {
    text-align: center;
    padding: 8px 60px;
    margin-top: 16px;
    font-family: 'Yanone Kaffeesatz';
    font-size: 3rem;
    color: #353536;
    /* text-shadow: #000 1px -1px, #000 -1px 1px, #000 1px 1px, #000 -1px -1px; */
  }

  > a {
    margin: 16px 0 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: #34af23;
    padding: 8px 16px;
    border-radius: 10px;
    /* border: 1px solid black; */

    &:hover {
      background: ${darken(0.1, '#34af23')};
    }

    > img {
      width: 40px;
    }

    > span {
      margin-left: 8px;
      font-family: 'Yanone Kaffeesatz';
      font-size: 2.5rem;
      color: #fff;
    }
  }

  @media (orientation: landscape) and (max-height: 480px) {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex: 1;

    h1 {
      margin-top: 100px;
      font-size: 3rem;
    }

    h2 {
      font-size: 2rem;
    }
  }

  @media (orientation: portrait) and (max-width: 490px) {
    justify-content: flex-start;

    h1 {
      margin-top: 100px;
      font-size: 3rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
`;

export const Social = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 16px;
`;

export const SocialItem = styled.a`
  & + a {
    margin-left: 24px;
  }

  > img {
    width: 40px;
  }
`;
