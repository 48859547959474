import React from 'react';

import {
  Container,
  MainLogo,
  MainContent,
  Title,
  Citacao,
  Ref,
  Text,
} from './styles';

import Navbar from '../Navbar';

import logo from '../../assets/logo.svg';

const History: React.FC = () => {
  return (
    <Container>
      <MainLogo>
        <img src={logo} alt="Logo" />
      </MainLogo>
      <Navbar />
      <MainContent>
        <Title>Sobre nós</Title>
        <Citacao>
          – Cozinhar não é serviço, meu neto – disse ela.
          <br />– Cozinhar é um modo de amar os outros.
        </Citacao>
        <Ref>Trecho de “A avó, a cidade e o semáforo”, conto de Mia Couto</Ref>
        <Text>
          E é assim, que nos enredamos em sabores e sensações da culinária. É
          uma viagem no tempo, uma busca na lembrança, uma respiração mais
          profunda... um afago que atravessa o paladar percorrendo todos os
          outros sentidos: trazendo sentido para o nosso momento e criando novas
          lembranças. Somos capazes de lembrar a textura, sabor e aparência
          daquela empadinha maravilhosa! É uma sensação que quase não cabe em
          palavras. Então, deixo aqui o convite: Conheça “A Tia da Empada”,
          vivencie esse amor e construa novas memórias.
        </Text>
      </MainContent>
    </Container>
  );
};

export default History;
