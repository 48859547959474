import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './components/Home';
import Menu from './components/Menu';
import History from './components/History';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/menu" component={Menu} />
      <Route path="/sobre" component={History} />
    </Switch>
  );
};

export default Routes;
