import React from 'react';

import { Container, MainLogo, MainContent, Social, SocialItem } from './styles';

import Navbar from '../Navbar';

import logo from '../../assets/logo.svg';
import whatsappLogo from '../../assets/whatsapp.svg';
import instagramLogo from '../../assets/instagram-sketched.svg';
import facebookLogo from '../../assets/facebook.svg';

const Home: React.FC = () => {
  return (
    <Container>
      <MainLogo>
        <img src={logo} alt="Logo" />
      </MainLogo>
      <Navbar />
      <MainContent>
        <h1>Vontade de comer uma empada, né?</h1>
        <h2>Encomende já pelo Whats!</h2>
        <h2>Aceitamos cartões de crédito e débito</h2>
        <a
          href="https://wa.me/5511974570701"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={whatsappLogo} alt="Whatsapp" />
          <span>Eu Quero!</span>
        </a>
        <Social>
          <SocialItem
            href="https://www.instagram.com/atiadaempada/"
            target="_blank"
          >
            <img src={instagramLogo} alt="Instagram" />
          </SocialItem>
          <SocialItem
            href="https://www.facebook.com/atiadaempada/"
            target="_blank"
            type="button"
          >
            <img src={facebookLogo} alt="Facebook" />
          </SocialItem>
        </Social>
      </MainContent>
    </Container>
  );
};

export default Home;
