import React, { useState, useCallback } from 'react';

import { Container } from './styles';

import RightNav from '../RightNav';

const BurgerNav: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  return (
    <>
      <Container open={showMenu} onClick={handleShowMenu}>
        <div />
        <div />
        <div />
      </Container>
      <RightNav open={showMenu} />
    </>
  );
};

export default BurgerNav;
