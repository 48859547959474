import styled, { css } from 'styled-components';

import { lighten } from 'polished';

import { RightNavProps } from './index';

export const Container = styled.ul<RightNavProps>`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: 'Yanone Kaffeesatz';
  font-size: 2.5rem;
  z-index: 999;

  a {
    text-decoration: none;
    color: #000;
  }

  li {
    padding: 18px 10px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: ${lighten(0.5, '#000')};
    }
  }

  @media (max-width: 880px) {
    flex-flow: column nowrap;
    position: fixed;
    width: 300px;
    ${props =>
      props.open
        ? css`
            right: 0;
          `
        : css`
            right: -300px;
          `};
    /* background: #f58634; */
    background: #f5886c;
    height: 100%;
    justify-content: flex-start;
    padding: 5rem 15px 0;
    transition: right 0.3s ease-in-out;

    li {
      /* border-bottom: 2px solid #fbb462; */
      border-bottom: 2px solid #fadad2;
    }

    li + li {
      margin-top: 10px;
    }
  }
`;
