import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
`;
