import styled from 'styled-components';

import bgEmpada from '../../assets/empada.jpg';
import bgEmpada2 from '../../assets/empada2.jpg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;

  @media (orientation: portrait) and (max-width: 490px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    min-height: 100%;
  }

  @media (orientation: landscape) and (max-height: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    min-height: 100%;
  }
`;

export const MainLogo = styled.div`
  width: 50%;
  height: 100%;
  background: url(${bgEmpada}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  z-index: 20;
  border-right: 14px solid #f5886c;

  > img {
    width: 300px;
    margin-right: -157px;
    margin-bottom: 300px;
    z-index: 21;
  }

  @media (orientation: landscape) and (max-height: 480px) {
    height: 200px;
    width: 100%;
    max-width: 100%;
  }

  @media (orientation: portrait) and (max-width: 490px) {
    width: 100%;
    min-height: 280px;
    background: url(${bgEmpada2}) no-repeat center;
    background-size: cover;
    border-right: 0;
    border-bottom: 10px solid #f5886c;

    > img {
      align-self: flex-end;
      width: 200px;
      margin: 0 auto -100px;
      z-index: 21;
    }
  }

  @media (orientation: landscape) and (max-height: 480px) {
    width: 100%;
    height: 200px;
    min-height: 200px;
    background: url(${bgEmpada2}) no-repeat center;
    background-size: cover;
    border-right: 0;
    border-bottom: 10px solid #f5886c;
    align-items: center;

    > img {
      align-self: flex-end;
      width: 200px;
      margin: 0 auto -100px;
      z-index: 21;
    }
  }
`;

export const MainContent = styled.div`
  flex: 1;
  background: #deac79;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
  width: 100%;
  padding-bottom: 32px;
  overflow-y: auto;

  @media (orientation: landscape) and (max-height: 480px) {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex: 1;
  }

  @media (orientation: portrait) and (max-width: 490px) {
    justify-content: flex-start;
  }
`;

export const Title = styled.h1`
  text-align: center;
  padding: 8px 60px;
  font-family: 'Dancing Script', cursive;
  font-size: 4rem;
  color: #fff;
  width: 100%;
  background-color: #f5886c;
  margin-top: 100px;

  @media (orientation: portrait) and (max-width: 490px) {
    font-size: 3.2rem;
  }
`;

export const MenuContainer = styled.div`
  width: auto;
  padding: 0;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: 150px;

  @media (orientation: portrait) and (max-width: 490px) {
    margin-left: 0;
    padding: 0 0 0 8px;
  }

  @media (orientation: landscape) and (max-height: 480px) {
    margin-left: 0;
    padding: 0 16px;
  }
`;

export const MenuItem = styled.div`
  margin-top: 8px;
  width: 250px;
  height: 250px;
  /* border: 1px solid black; */
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #f5886c;

  > img {
    width: 100%;
    height: 210px;
  }

  > span {
    width: 100%;
    height: 40px;
    background-color: #f5886c;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'Dancing Script', cursive;
    font-size: 2rem;
    color: #fff;
    text-align: center;
  }
`;
