import React from 'react';

import {
  Container,
  MainLogo,
  MainContent,
  Title,
  MenuContainer,
  MenuItem,
} from './styles';

import Navbar from '../Navbar';

import logo from '../../assets/logo.svg';
import frango from '../../assets/menu/frango.jpg';
import calabresa from '../../assets/menu/calabresa.jpg';
import carneseca from '../../assets/menu/carneseca.jpg';
import brocolis from '../../assets/menu/brocolis.jpg';
import palmito from '../../assets/menu/palmito.jpg';

const Menu: React.FC = () => {
  return (
    <Container>
      <MainLogo>
        <img src={logo} alt="Logo" />
      </MainLogo>
      <Navbar />
      <MainContent>
        <Title>Menu</Title>
        <MenuContainer>
          <MenuItem>
            <img src={frango} alt="Frango" />
            <span>Frango - R$ 5</span>
          </MenuItem>
          <MenuItem>
            <img src={calabresa} alt="Calabresa" />
            <span>Calabresa c/ Catupiry - R$ 5</span>
          </MenuItem>
          <MenuItem>
            <img src={brocolis} alt="Brócolis" />
            <span>Brócolis c/ Catupiry - R$ 5</span>
          </MenuItem>
          <MenuItem>
            <img src={palmito} alt="Palmito" />
            <span>Palmito - R$ 6</span>
          </MenuItem>
          <MenuItem>
            <img src={carneseca} alt="Carne Seca" />
            <span>Carne Seca c/ Catupiry - R$ 6</span>
          </MenuItem>
        </MenuContainer>
      </MainContent>
    </Container>
  );
};

export default Menu;
