import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export interface RightNavProps {
  open: boolean;
}

const RightNav: React.FC<RightNavProps> = ({ open }) => {
  return (
    <Container open={open}>
      <Link to="/">
        <li>Home</li>
      </Link>
      <Link to="/sobre">
        <li>Sobre nós</li>
      </Link>
      <Link to="/menu">
        <li>Menu</li>
      </Link>
      <a
        href="https://wa.me/5511974570701"
        target="_blank"
        rel="noopener noreferrer"
      >
        <li>Eu Quero!</li>
      </a>
    </Container>
  );
};

export default RightNav;
