import styled from 'styled-components';

interface BurgerProps {
  open: boolean;
}

export const Container = styled.div<BurgerProps>`
  width: 3rem;
  height: 3rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: none;
  cursor: pointer;
  z-index: 9999;

  div {
    width: 3rem;
    height: 0.4rem;
    background-color: ${props => (props.open ? '#ccc' : '#fff')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${props => (props.open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${props =>
        props.open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${props => (props.open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${props => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
    }

    @media (orientation: portrait) and (max-width: 490px) {
      background-color: ${props => (props.open ? '#ccc' : '#f5886c')};
    }
    @media (orientation: landscape) and (max-height: 480px) {
      background-color: ${props => (props.open ? '#ccc' : '#fff')};
    }
  }

  @media (max-width: 880px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    align-items: center;
  }
`;
